define("admin/controllers/missions/edit", ["exports", "moment-timezone", "admin/config/environment", "admin/data/cloud-options", "admin/data/duration-options", "admin/data/number-of-buildings-options", "admin/data/payout-to-options", "admin/data/line-item-reasons", "admin/data/scheduling-options", "admin/data/sla-options", "admin/data/quality-check-issues", "admin/data/weekend-filter-options", "admin/utils/timezone-without-changing-time", "admin/utils/send-error-to-sentry", "admin/utils/get-error-message-from-response"], function (_exports, _momentTimezone, _environment, _cloudOptions, _durationOptions, _numberOfBuildingsOptions, _payoutToOptions, _lineItemReasons, _schedulingOptions, _slaOptions, _qualityCheckIssues, _weekendFilterOptions, _timezoneWithoutChangingTime, _sendErrorToSentry, _getErrorMessageFromResponse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const [PILOT, ANALYST, EDITOR] = ['pilot', 'analyst', 'editor'];
  const [STRIPE, PAYPAL, OTHER] = ['Stripe', 'Paypal', 'Other'];
  var _default = _exports.default = Ember.Controller.extend({
    sessionAccount: Ember.inject.service(),
    showPaymentModal: false,
    showPaymentErrorModal: false,
    showReminderModal: false,
    showFullScreenOrtho: false,
    reshootModalVisible: false,
    cancelModalVisible: false,
    rescheduleModalVisible: false,
    ratingModalVisible: false,
    holdMissionModalVisible: false,
    capacityModalVisible: false,
    showEditPackageModal: false,
    showPayoutLineItemForm: false,
    selectedShot: null,
    currentView: null,
    queryParams: ['activeTab'],
    activeTab: 'edit',
    cloudOptions: _cloudOptions.default,
    analystOptions: Ember.computed('model.analysts', function () {
      console.log('model', this.model.analysts);
      return this.get('model.analysts').map(analyst => {
        return Ember.Object.create({
          label: analyst.fullName,
          value: analyst.id
        });
      });
    }),
    durationOptions: _durationOptions.default,
    numberOfBuildingsForSelect: _numberOfBuildingsOptions.default,
    payoutToForSelect: _payoutToOptions.default.map(function (option) {
      return option.value;
    }),
    payoutLineItemReasons: _lineItemReasons.payoutLineItemReasons,
    schedulingOptions: _schedulingOptions.default,
    slaOptions: _slaOptions.default,
    qualityCheckIssues: _qualityCheckIssues.default,
    weekendFilterOptions: _weekendFilterOptions.default,
    payout: null,
    lineItem: null,
    nextReasonIndex: 0,
    busy: false,
    shotMarkers: Ember.A([]),
    packageAddons: Ember.A([]),
    view: 'thumbnail',
    activeSubTab: 'package_and_pricing',
    tabAnchorIds: Ember.A(['package_and_pricing', 'mission_details', 'pilot_dispatch']),
    isEditingRefId: false,
    payoutLineItemLaborPrefill: null,
    showConfirmPayoutModal: false,
    showCaptureGroupModal: false,
    showWorkOrderModal: false,
    maxPayoutItemAmount: 3000,
    bulkModeEnabled: false,
    selectedAssetsNumbers: Ember.A([]),
    selectedQCFilters: Ember.A([]),
    selectedShotId: null,
    moveAssetsModalIsVisible: false,
    removeAssetsModalIsVisible: false,
    airspaceProvider: _environment.default.useAirhubForAirspaceChecks ? 'Airhub' : 'Airbus',
    bulkActionIsDisabled: Ember.computed('selectedAssetsNumbers.length', function () {
      return this.get('selectedAssetsNumbers.length') === 0;
    }),
    canLoginAsClient: Ember.computed('model.admin', function () {
      return this.get('model.currentAdmin.roles').includes('auth0_access');
    }),
    canAssignPilot: Ember.computed('model.mission.pilot_id', function () {
      return this.get('model.mission.pilot_id') === null;
    }),
    // eslint-disable-next-line ember/use-brace-expansion
    disableStatusDropDown: Ember.computed('model.mission.isOnHold', 'model.mission.status', 'model.currentAdmin', function () {
      return this.get('model.mission.isOnHold') || ['invoice_needed', 'invoiced'].includes(this.get('model.mission.status')) && !this.get('model.currentAdmin.isFinanceManager');
    }),
    missionTimezone: Ember.computed('model.mission.location.timezone_id', function () {
      return this.get('model.mission.location.timezone_id') || 'UTC';
    }),
    captureGroupActionName: Ember.computed('model.mission.site_inspection.capture_group_id', function () {
      if (this.get('model.mission.site_inspection.capture_group_id')) {
        return 'Move';
      } else {
        return 'Add';
      }
    }),
    workOrderActionName: Ember.computed('model.mission.site_inspection.id', function () {
      if (this.get('model.mission.site_inspection.id')) {
        return 'Move';
      } else {
        return 'Add';
      }
    }),
    formattedPromisedDate: Ember.computed('model.mission.promised_delivery_date', function () {
      var promisedDate;
      promisedDate = (0, _timezoneWithoutChangingTime.default)((0, _momentTimezone.default)(this.get('model.mission.promised_delivery_date')), this.get('missionTimezone'));
      return this.get('model.mission.promised_delivery_date') && promisedDate.format('MM/DD/YYYY') || null;
    }),
    catalogItemMission: Ember.computed('model.mission.package.catalog_items', function () {
      if (this.get('model.mission.package.catalog_items.length')) {
        return true;
      } else {
        return false;
      }
    }),
    catalogItemsSlaDays: Ember.computed('model.mission.package.catalog_items', function () {
      var catalog_items;
      if (this.get('model.mission.package.catalog_items.length')) {
        if (this.model.mission.catalog_items.length) {
          catalog_items = this.model.mission.catalog_items;
        } else {
          catalog_items = this.get('model.mission.package.catalog_items');
        }
        return catalog_items.filter(function () {
          return function (ci) {
            return ci.delivery_sla;
          };
        }(this)).sortBy('delivery_sla').reverse().map(function () {
          return function (ci) {
            return {
              "name": ci.name,
              "days": Math.round(ci.delivery_sla / 24),
              "startsOn": ci.delivery_sla_starts_on.split('_').map(function (name) {
                return name.capitalize();
              }).join(' ')
            };
          };
        }(this));
      } else {
        return [];
      }
    }),
    packageDeliverySlaDays: Ember.computed('model.mission.package.delivery_sla', function () {
      return Math.round(this.get('model.mission.package.delivery_sla') / 24);
    }),
    packageDeliverySlaStartsOn: Ember.computed('model.mission.package.delivery_sla_starts_on', function () {
      const delivery_sla_starts_on = this.get('model.mission.package.delivery_sla_starts_on');
      if (delivery_sla_starts_on) {
        return delivery_sla_starts_on.split('_').map(name => name.capitalize()).join(' ');
      }
      return null;
    }),
    formattedPlannedDate: Ember.computed('model.mission.planned_delivery_date', function () {
      var plannedDate;
      plannedDate = (0, _timezoneWithoutChangingTime.default)((0, _momentTimezone.default)(this.get('model.mission.planned_delivery_date')), this.get('missionTimezone'));
      return this.get('model.mission.planned_delivery_date') && plannedDate.format('MM/DD/YYYY') || null;
    }),
    promisedDatepickerOptions: Ember.computed('model.mission.created_on', function () {
      return {
        collapse: true,
        focusOnShow: false,
        viewMode: 'days',
        format: 'MM/DD/YYYY',
        useCurrent: false,
        showClear: true,
        widgetPositioning: {
          horizontal: 'auto',
          vertical: 'auto'
        },
        icons: {
          date: 'fa fa-calendar',
          previous: 'fa fa-angle-left',
          next: 'fa fa-angle-right'
        },
        minDate: (0, _momentTimezone.default)(this.get('model.mission.created_on')).format('MM/DD/YYYY')
      };
    }),
    plannedDatepickerOptions: Ember.computed('model.mission.{created_on,promised_delivery_date}', function () {
      return {
        collapse: true,
        focusOnShow: false,
        viewMode: 'days',
        format: 'MM/DD/YYYY',
        useCurrent: false,
        showClear: true,
        widgetPositioning: {
          horizontal: 'auto',
          vertical: 'auto'
        },
        icons: {
          date: 'fa fa-calendar',
          previous: 'fa fa-angle-left',
          next: 'fa fa-angle-right'
        },
        minDate: (0, _momentTimezone.default)(this.get('model.mission.promised_delivery_date') || this.get('model.mission.created_on')).format('MM/DD/YYYY')
      };
    }),
    mission: Ember.computed('model.mission', function () {
      return this.get('model.mission');
    }),
    statusesForSelect: Ember.computed('model.{mission_statuses,currentAdmin}', function () {
      var disabledForClientUploadStatuses, disabledForStandardFlowStatuses;
      disabledForClientUploadStatuses = ['scheduling_call', 'confirmed', 'pilots_notified', 'pilot_accepted', 'flight_complete'];
      disabledForStandardFlowStatuses = ['upload_required'];
      const admin = this.get('model.currentAdmin');
      return this.get('model.mission_statuses').map(function (_this) {
        return function (item) {
          if (item.value === 'canceled') {
            item.disabled = 'true';
          }
          if (['invoiced', 'complete'].includes(item.value) && !admin.get('isFinanceManager')) {
            item.disabled = 'true';
          }
          if (_this.get('model.mission.client_upload')) {
            if (!disabledForClientUploadStatuses.includes(item.value)) {
              return Ember.Object.create(item);
            }
          } else {
            if (!disabledForStandardFlowStatuses.includes(item.value)) {
              return Ember.Object.create(item);
            }
          }
        };
      }(this)).filter(Boolean);
    }),
    selectedStatus: Ember.computed('model.mission.status', function () {
      return this.get('statusesForSelect').findBy('value', this.get('model.mission.status'));
    }),
    drones: Ember.computed('model.drones', function () {
      return this.get('model.drones');
    }),
    breadCrumbs: Ember.computed("model.mission", function () {
      var parent, path, type;
      type = this.get('model.mission.mission_type');
      switch (type) {
        case 'client':
          path = 'missions';
          break;
        case 'training':
          path = 'missions.training_missions';
      }
      parent = this.toTitleCase(type);
      return [{
        label: parent + " Mission",
        path
      }, {
        label: this.get('model.mission.id'),
        model: this.get('model.mission')
      }];
    }),
    parentCrumb: Ember.computed('model.mission', function () {
      var type;
      type = this.get('model.mission.mission_type');
      return this.toTitleCase(type);
    }),
    /* eslint-disable ember/no-observers */
    observePilotPayout: Ember.observer('model.payout.payout_to', function () {
      var paymentProcessor;
      this.get('model.payout.payout_line_items').toArray().forEach(function (item) {
        return item.destroyRecord();
      });
      this.get('model.payout').set('amount', 0);
      this.set('nextReasonIndex', 0);
      this.set('showPayoutLineItemForm', false);
      switch (this.get('model.payout.payout_to')) {
        case PILOT:
          paymentProcessor = this.get('model.mission.pilot.payment_processor') === PAYPAL ? PAYPAL : `${STRIPE} (${this.get('model.defaultLegalEntity.name')})`;
          this.set('model.payout.pilot', this.get('model.mission.pilot'));
          this.set('model.payout.payee_name', this.get('model.mission.pilot.fullName'));
          this.set('model.payout.currency', this.get('model.mission.estimated_payouts').findBy('payout_type', PILOT).currency);
          return this.set('model.payout.payment_processor', paymentProcessor);
        case ANALYST:
          this.set('model.payout.payee_name', null);
          this.set('model.payout.pilot', null);
          this.set('model.payout.notes', null);
          this.set('model.payout.currency', this.get('model.mission.estimated_payouts').findBy('payout_type', ANALYST).currency);
          return this.set('model.payout.payment_processor', OTHER);
        case EDITOR:
          this.set('model.payout.pilot', null);
          this.set('model.payout.notes', null);
          this.set('model.payout.currency', this.get('model.mission.estimated_payouts').findBy('payout_type', EDITOR).currency);
          return this.set('model.payout.payment_processor', OTHER);
        default:
          this.set('model.payout.pilot', null);
          this.set('model.payout.notes', null);
          this.set('model.payout.amount', null);
          this.set('model.payout.currency', null);
          return this.set('model.payout.payment_processor', null);
      }
    }),
    observeLineItemReason: Ember.observer('lineItem.reason', function () {
      if (!this.get('lineItem')) {
        return;
      }
      if (this.get('lineItem.reason') !== 'labor') {
        return this.set('lineItem.amount', 0);
      } else {
        return this.set('lineItem.amount', this.payoutLineItemLaborPrefill);
      }
    }),
    /* eslint-enable ember/no-observers */

    showStripeLegalEntityWarning: Ember.computed('model.payout.{payout_to,payment_processor}', function () {
      var legalEntityName, pilotLegalEntities, legalEntityNamePartial, receiverStripeAccounts;
      const payoutTo = this.get('model.payout.payout_to');
      const paymentProcessor = this.get('model.payout.payment_processor');
      if (![PILOT, ANALYST].includes(payoutTo)) {
        return false;
      } else if ([OTHER, PAYPAL].includes(paymentProcessor)) {
        return false;
      } else {
        legalEntityName = paymentProcessor != null ? (legalEntityNamePartial = paymentProcessor.split('(')[1]) != null ? legalEntityNamePartial.split(')')[0] : void 0 : void 0;
        if (payoutTo === PILOT) {
          receiverStripeAccounts = this.get('model.mission.pilot.stripe_accounts');
        } else if (payoutTo === ANALYST) {
          receiverStripeAccounts = this.get('model.payout.pilot.stripe_accounts');
        }
        pilotLegalEntities = (receiverStripeAccounts != null ? receiverStripeAccounts.filterBy('autopay_enabled', true).mapBy('legal_entity.name') : void 0) || [];
        return !pilotLegalEntities.includes(legalEntityName);
      }
    }),
    missionPayoutsReverse: Ember.computed('model.mission.payouts.[]', function () {
      return this.get('model.mission.payouts').toArray().sortBy('created_on');
    }),
    arrayOfTotalPayoutByCurrency: Ember.computed('model.mission.payouts.[]', function () {
      var result, uniqueCurrencies;
      result = [];
      uniqueCurrencies = this.get('model.mission.payouts').getEach('currency').uniq();
      uniqueCurrencies.forEach(function (_this) {
        return function (currency) {
          return result.push({
            currency,
            amount: _this.get('model.mission.payouts').filterBy('currency', currency).filterBy('status', 'complete').getEach('amount').reduce(function (m, n) {
              return parseInt(m) + parseInt(n);
            }, 0)
          });
        };
      }(this));
      return result;
    }),
    invitationDispatchInProgress: Ember.computed('model.mission.status', function () {
      return this.get('model.mission.pilot_invitations_dispatch.dispatch_status') === 'in_progress' && this.get('model.mission.status') === 'pilots_notified' && !this.get('model.mission.isOnHold');
    }),
    showInvitePilotLink: Ember.computed('model.mission.status', function () {
      return !this.get('invitationDispatchInProgress') && ['confirmed', 'reshoot', 'pilots_notified'].includes(this.get('model.mission.status'));
    }),
    pilotNotAssigned: Ember.computed('model.mission.status', function () {
      return ['created', 'confirmed', 'reshoot', 'pilots_notified'].includes(this.get('model.mission.status'));
    }),
    disableCancelMission: Ember.computed('model.mission.status', function () {
      return ['rejected', 'unfulfilled', 'canceled', 'complete'].includes(this.get('model.mission.status'));
    }),
    showPaymentTransition: Ember.computed('model.mission.{status,client,skipInProduction,client.invoiceable}', function () {
      var mission;
      mission = this.get('model.mission');
      if (mission.get('skipInProduction') && this.get('model.mission.status') === 'assets_classified') {
        return !mission.get('client.invoiceable');
      }
      return !mission.get('client.invoiceable') && ['in_production', 'awaiting_payment'].includes(this.get('model.mission.status'));
    }),
    show3pfa: Ember.computed(function () {
      return !!_environment.default.show3pfa;
    }),
    statusSubText: Ember.computed('model.mission.{package.auto_dispatch_enabled,status,isReshoot}', function () {
      if (this.get('model.mission.isReshoot') && this.get('model.mission.status') === 'created' && this.get('model.mission.package.auto_dispatch_enabled')) {
        return "Auto-dispatch at confirm is disabled for this mission due to reshoot";
      }
      return null;
    }),
    toTitleCase(str) {
      if (str === null || str === void 0) {
        return '';
      } else {
        return str.replace(/\w\S*/g, function (txt) {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
      }
    },
    setTab(tab) {
      return this.set('activeTab', tab);
    },
    loadCustomPackageData() {
      if (this.get('packageAddons.length') === 0) {
        return this.set('packageAddons', this.store.findAll('package_addon'));
      }
    },
    showAssetProcesses: Ember.computed('model.mission.status', function () {
      return ['assets_classified', 'processing_shots', 'in_production'].includes(this.get('model.mission.status')) && this.get('model.mission.shots').filter(function (shot) {
        return shot.get('hasPostProcess');
      }).length > 0;
    }),
    groupedDropships: Ember.computed('model.mission.dropships', function () {
      var result;
      result = [];
      this.get('model.mission.dropships').forEach(function (item) {
        var currentTrackInfo, group;
        currentTrackInfo = Ember.Object.create({
          inboundLink: item.inboundLink,
          inboundShippingCost: item.get('inbound_shipping_cost'),
          outboundLink: item.outboundLink,
          outboundShippingCost: item.get('outbound_shipping_cost'),
          returnDate: item.return_date
        });
        group = result.find(function (resItem) {
          var resInfo;
          resInfo = resItem.get('trackInfo');
          return Ember.Object.keys(resInfo).every(function () {
            return function (key) {
              return Ember.Object.prototype.hasOwnProperty.call(currentTrackInfo, key) && currentTrackInfo[key] === resInfo[key];
            };
          }(this));
        });
        if (group) {
          return group.get('contents').pushObject(item);
        } else {
          return result.pushObject(Ember.Object.create({
            trackInfo: currentTrackInfo,
            contents: [item]
          }));
        }
      });
      result.forEach(function (collection) {
        var contents;
        contents = collection.get('contents');
        return collection.set('contents', contents.sortBy('inventory_item.inventory_type'));
      });
      return result;
    }),
    clientLoginParams: Ember.computed('missionNumber', function () {
      return {
        client_number: this.get('model.mission.client.id'),
        client_first_name: this.get('model.mission.client.first_name'),
        client_last_name: this.get('model.mission.client.last_name'),
        redirect_route: 'missions.show',
        mission_id: this.get('model.mission.id')
      };
    }),
    isPackageChangeable: Ember.computed('model.mission.status', function () {
      return ['created', 'confirmed', 'pilots_notified', 'scheduling_call', 'pilot_accepted'].includes(this.get('model.mission.status'));
    }),
    hasPaymentHistory: Ember.computed('model.mission.mission_payments.[]', function () {
      return Ember.isPresent(this.get('model.mission.mission_payments'));
    }),
    stripeLink: _environment.default.stripeChargeUrl,
    waiverText: Ember.computed('model.mission.waivers', function () {
      var baseText, date, displayDate, e, ext, i, index, j, len, len1, ref2, ref3, waiver;
      try {
        try {
          if (this.get('model.mission.waivers').get('lastObject').get('waiver_extensions.length') > 0) {
            date = this.get('model.mission.waivers').get('lastObject').get('waiver_extensions').get('lastObject').get('updated_at');
          } else {
            date = this.get('model.mission.waivers').get('lastObject').get('updated_at');
          }
        } catch (error1) {
          e = error1;
          return null;
        }
        displayDate = (0, _momentTimezone.default)(date).format('MM/DD/YYYY');
        baseText = "<div><div><strong>Waivers & extensions:</strong></div> Pilot needs to present the document while on site. Show the waiver at the gate, the last extension was approved on " + displayDate + ".</div><br/>";
        ref2 = this.get('model.mission.waivers').toArray();
        for (i = 0, len = ref2.length; i < len; i++) {
          waiver = ref2[i];
          if (waiver == null) {
            continue;
          }
          baseText += "<div><strong>Airport Waiver:</strong> " + waiver.get('url') + "</div>";
          if (Ember.isPresent(waiver.get('waiver_extensions').toArray())) {
            baseText += '<br/>';
          }
          ref3 = waiver.get('waiver_extensions').toArray();
          for (index = j = 0, len1 = ref3.length; j < len1; index = ++j) {
            ext = ref3[index];
            baseText += "<div><strong>Waiver Extension " + (index + 1) + ":<strong> " + ext.get('link') + "</div>";
          }
        }
        return baseText;
      } catch (error1) {
        e = error1;
        console.log(e);
        alert('Error getting waiver info. Please manually obtain the waiver for this mission.');
        return null;
      }
    }),
    airspaceInfo: Ember.computed('model.mission.{advisories.[],laanc_exemptions.[]}', function () {
      var result;
      result = [];
      this.get('model.mission.advisories').forEach(function (laanc) {
        return result.push({
          laanc: laanc.laanc_enabled ? 'Manual' : 'Advisory',
          description: laanc.name + " " + laanc.code
        });
      });
      this.get('model.mission.laanc_exemptions').forEach(function (laanc) {
        return result.push({
          laanc: 'Automatic',
          description: laanc.name
        });
      });
      return result;
    }),
    airspaceNotices: Ember.computed('model.mission.airspace_briefing.notices.[]', function () {
      if (!this.get('model.mission.airspace_briefing.notices')) {
        return;
      }
      return this.get('model.mission.airspace_briefing.notices').map(function (notice) {
        return notice.message;
      }).join("\n\n");
    }),
    maxAltitude: Ember.computed('model.mission.airspace_briefing.max_altitude', function () {
      if (!this.get('model.mission.airspace_briefing.max_altitude')) {
        return;
      }
      return this.get('model.mission.airspace_briefing.max_altitude').toString().concat('ft');
    }),
    laancEnabled: Ember.computed('model.mission.airspace_briefing.laanc_enabled', function () {
      if (this.get('model.mission.airspace_briefing.laanc_enabled') === void 0) {
        return;
      }
      return this.get('model.mission.airspace_briefing.laanc_enabled');
    }),
    autoAuthorization: Ember.computed('model.mission.airspace_briefing.auto_authorization', function () {
      if (this.get('model.mission.airspace_briefing.auto_authorization') === void 0) {
        return;
      }
      return this.get('model.mission.airspace_briefing.auto_authorization');
    }),
    airspaceClass: Ember.computed('model.mission.airspace_briefing.airspace_class', function () {
      if (!this.get('model.mission.airspace_briefing.airspace_class')) {
        return;
      }
      return this.get('model.mission.airspace_briefing.airspace_class');
    }),
    waivers: Ember.computed('model.mission.airspace_briefing.waivers', function () {
      if (!this.get('model.mission.airspace_briefing.waivers')) {
        return;
      }
      return this.get('model.mission.airspace_briefing.waivers');
    }),
    supportedCountry: Ember.computed('model.mission.location.airspace_supported_country', function () {
      if (!this.get('model.mission.location.airspace_supported_country')) {
        return;
      }
      return this.get('model.mission.location.airspace_supported_country');
    }),
    estimatedPilotPayoutCurrencyCode: Ember.computed('model.mission.estimated_payouts', function () {
      return this.model.mission.get('estimated_pilot_payout_obj').get('currency').code;
    }),
    estimatedEditorPayoutCurrencyCode: Ember.computed('model.mission.estimated_payouts', function () {
      return this.model.mission.get('estimated_editor_payout_obj').get('currency').code;
    }),
    estimatedAnalystPayoutCurrencyCode: Ember.computed('model.mission.estimated_payouts', function () {
      return this.model.mission.get('estimated_analyst_payout_obj').get('currency').code;
    }),
    estimatedRoofMeasurementPayoutCurrencyCode: Ember.computed('model.mission.estimated_payouts', function () {
      return this.model.mission.get('estimated_roof_measurement_payout_obj').get('currency').code;
    }),
    paymentMethodDropdownDisabled: Ember.computed('model.payout.payout_to', function () {
      return ![PILOT, ANALYST].includes(this.get('model.payout.payout_to'));
    }),
    payoutLineItemAddDisabled: Ember.computed('lineItem.amount', function () {
      return this.get('lineItem.amount') === 0;
    }),
    addPayoutButtonDisabled: Ember.computed('model.payout.payout_line_items.@each', function () {
      return this.get('model.payout.payout_line_items.length') === 0;
    }),
    paymentMethodOptions: Ember.computed('model.mission.pilot', function () {
      var options;
      options = [];
      if (!this.get('model.mission.pilot')) {
        return;
      }
      if (this.get('model.mission.pilot.payment_processor') === PAYPAL) {
        options.push(PAYPAL);
      }
      this.get('model.stripeLegalEntities').forEach(function (entity) {
        return options.push(`${STRIPE} (${entity.get('name')})`);
      });
      options.push(OTHER);
      return options;
    }),
    paymentMethodPlaceholder: Ember.computed('model.{mission.pilot,payout.payout_to}', function () {
      if (!this.get('model.mission.pilot') || this.get('model.payout.payout_to') !== PILOT) {
        return;
      }
      return `${STRIPE} (${this.get('model.mission.legal_entity.name') || this.get('model.defaultLegalEntity.name')})`;
    }),
    payingToAnalyst: Ember.computed('model.payout.payout_to', function () {
      return this.get('model.payout.payout_to') === ANALYST;
    }),
    bulkActionSuccessHandler(message, modalToClose) {
      this.flashMessages.success(message);
      this.get('model.mission.shots').forEach(shot => {
        shot.set('images', []);
        return shot.loadImagesPage(1, 90);
      });
      this.set(modalToClose, false);
      this.set('selectedAssetsNumbers', Ember.A([]));
    },
    bulkActionFailureHandler(response, defaultErrorMessage) {
      if (response.status == 422 && response.responseJSON?.error) return this.flashMessages.error(response.responseJSON.error.base);
      console.error(response);
      this.flashMessages.error(defaultErrorMessage);
    },
    actions: {
      showMoveAssetsModal() {
        if (this.get('bulkActionIsDisabled')) return;
        this.set('moveAssetsModalIsVisible', true);
      },
      closeMoveAssetsModal() {
        this.set('moveAssetsModalIsVisible', false);
      },
      moveAssets() {
        const data = {
          image_numbers: this.get('selectedAssetsNumbers'),
          shot_id: this.get('selectedShotId')
        };
        this.get('store').adapterFor('mission').moveAssets(this.get('model.mission.id'), data).then(() => {
          this.bulkActionSuccessHandler(`${this.get('selectedAssetsNumbers.length')} assets were moved successfully!`, 'moveAssetsModalIsVisible');
        }).catch(response => {
          this.bulkActionFailureHandler(response, 'Failed to move assets');
        });
      },
      showRemoveAssetsModal() {
        if (this.get('bulkActionIsDisabled')) return;
        this.set('removeAssetsModalIsVisible', true);
      },
      closeRemoveAssetsModal() {
        this.set('removeAssetsModalIsVisible', false);
      },
      removeAssets() {
        const data = {
          image_numbers: this.get('selectedAssetsNumbers')
        };
        this.get('store').adapterFor('mission').removeAssets(this.get('model.mission.id'), data).then(() => {
          this.bulkActionSuccessHandler(`${this.get('selectedAssetsNumbers.length')} assets were removed successfully!`, 'removeAssetsModalIsVisible');
        }).catch(response => {
          this.bulkActionFailureHandler(response, 'Failed to remove assets');
        });
      },
      switchBulkMode() {
        this.toggleProperty('bulkModeEnabled');
      },
      selectAssets(assets, checked) {
        const selectedAssetsNumbers = this.get('selectedAssetsNumbers');
        const assetsNumbers = assets.getEach('id');
        const newSelectedAssetNumbers = checked ? [...selectedAssetsNumbers, ...assetsNumbers] : selectedAssetsNumbers.filter(number => !assetsNumbers.includes(number));
        this.set('selectedAssetsNumbers', Array.from(new Set(newSelectedAssetNumbers)));
      },
      selectQCFilters(newSelectedQCFilters) {
        this.set('selectedQCFilters', newSelectedQCFilters);
      },
      setPromisedDateCallback(value) {
        var plannedDateTimePicker, plannedMinDate;
        if (value.date) {
          this.set('model.mission.promised_delivery_date', (0, _timezoneWithoutChangingTime.default)((0, _momentTimezone.default)(value.date), this.get('missionTimezone')).format());
          plannedDateTimePicker = Ember.$('#formGroupPlannedDeliveryDate input');
          if (!!this.get('model.mission.promised_delivery_date') && !!this.get('model.mission.planned_delivery_date') && !!plannedDateTimePicker && (0, _momentTimezone.default)(this.get('model.mission.promised_delivery_date')).isAfter(this.get('model.mission.planned_delivery_date'))) {
            this.set('model.mission.planned_delivery_date', '');
            plannedDateTimePicker.data('DateTimePicker').date((0, _timezoneWithoutChangingTime.default)((0, _momentTimezone.default)(value.date), this.get('missionTimezone')));
          }
          plannedMinDate = (0, _momentTimezone.default)(this.get('model.mission.promised_delivery_date') || this.get('mission.created_on'));
          if (plannedDateTimePicker) {
            return plannedDateTimePicker.data('DateTimePicker').minDate(plannedMinDate.format('MM/DD/YYYY'));
          }
        } else {
          return this.set('model.mission.promised_delivery_date', null);
        }
      },
      openPilotAssignmentModal() {
        return this.send("openModal", "missions.pilots-assign-modal", this.get("model"));
      },
      setPlannedDateCallback(value) {
        if (value.date) {
          return this.set('model.mission.planned_delivery_date', (0, _timezoneWithoutChangingTime.default)((0, _momentTimezone.default)(value.date), this.get('missionTimezone')).format());
        } else {
          return this.set('model.mission.planned_delivery_date', null);
        }
      },
      copyWaiverText() {
        var range;
        range = document.createRange();
        range.selectNode(document.getElementById("waiverText"));
        window.getSelection().removeAllRanges();
        window.getSelection().addRange(range);
        document.execCommand("copy");
        window.getSelection().removeAllRanges();
        Ember.$('#copyWaiverText').text('Copied');
      },
      togglePackageModal() {
        this.toggleProperty('showEditPackageModal');
        this.loadCustomPackageData();
      },
      fireBusy(promise) {
        return this.send('busy', promise);
      },
      pay(mission) {
        if (mission.get('credit_card') != null || mission.get('isFree')) {
          this.set('showPaymentModal', true);
        } else {
          this.set('showReminderModal', true);
        }
      },
      filterMap(shot) {
        if (this.get('selectedShot') === shot) {
          this.set('selectedShot', null);
          return this.set('model.filteredMapImages', this.get('model.mission.image_markers'));
        } else {
          this.set('model.filteredMapImages', shot.image_markers);
          return this.set('selectedShot', shot);
        }
      },
      addMarkersToMap(shot) {
        var markers, shotMarkers;
        shotMarkers = shot.image_markers.toArray();
        if (this.get('shotMarkers').length === 0) {
          this.set('model.missionMarkers', shotMarkers);
        } else {
          markers = this.get('model.missionMarkers').pushObjects(shotMarkers).flat();
          this.set('model.missionMarkers', markers);
        }
        return this.get('shotMarkers').push(shot.id);
      },
      removeMarkersFromMap(shot) {
        var markers, newMarkers, shotMarkers;
        newMarkers = this.get('shotMarkers').filter(function (value) {
          return value !== shot.id;
        });
        this.set('shotMarkers', newMarkers);
        shotMarkers = shot.image_markers.toArray();
        if (newMarkers.length === 0) {
          return this.set('model.missionMarkers', this.get('model.mission.image_markers').toArray());
        } else {
          markers = this.get('model.missionMarkers').filter(function (value) {
            return !shotMarkers.includes(value);
          });
          return this.set('model.missionMarkers', markers);
        }
      },
      toggleModal(attr) {
        this.set(attr, !this.get(attr));
      },
      togglePaymentModal() {
        this.toggleProperty('showPaymentModal');
      },
      toggleReminderModal() {
        this.toggleProperty('showReminderModal');
      },
      toggleReshootModal() {
        this.toggleProperty('reshootModalVisible');
      },
      toggleRescheduleModal() {
        this.toggleProperty('rescheduleModalVisible');
      },
      toggleCancelModal() {
        if (this.get('disableCancelMission')) {
          return;
        }
        this.toggleProperty('cancelModalVisible');
      },
      toggleRatingModal() {
        this.toggleProperty('ratingModalVisible');
      },
      togglePilotPayoutModal() {
        this.toggleProperty('pilotPayoutModalVisible');
      },
      toggleCapacityModal(upcomingDays) {
        var savedScroll;
        this.set('model.upcomingDays', upcomingDays);
        if (upcomingDays) {
          this.set('model.currDays', upcomingDays.slice(0, 3));
        }
        savedScroll = document.documentElement.scrollTop || document.body.scrollTop;
        this.toggleProperty('capacityModalVisible');
        /* eslint-disable ember/no-incorrect-calls-with-inline-anonymous-functions */
        Ember.run.scheduleOnce('afterRender', this, function () {
          return Ember.$(document).scrollTop(savedScroll);
        });
        /* eslint-enable ember/no-incorrect-calls-with-inline-anonymous-functions */
      },

      toggleHoldMissionModal() {
        this.toggleProperty('holdMissionModalVisible');
      },
      toggleFullscreenOrthoModal(ortho) {
        this.toggleProperty('showFullScreenOrtho');
        this.set('orthoPhotoMap', ortho);
      },
      adminScheduled(selectedDate) {
        var local_time;
        local_time = (0, _momentTimezone.default)(selectedDate).format('YYYY-MM-DD').toString();
        local_time = local_time + " 12:00";
        local_time = _momentTimezone.default.tz(local_time, this.get('model.mission.location.timezone_id')).toString();
        this.set('model.mission.scheduled_at', local_time);
        this.set('model.mission.admin_scheduled', true);
        return this.send('busy', this.get('model.mission').save());
      },
      setWeekendFilter(choice) {
        this.set('model.mission.weekend_schedule_filter', choice);
        if (choice === 'weekends_only') {
          this.set('model.mission.data_capture_within', '');
          return this.set('model.mission.data_capture_offset', '');
        }
      },
      reloadMission() {
        return this.get('model.mission').reload();
      },
      setTab(tab) {
        var markers;
        if (tab === 'assets') {
          this.get('model.mission').shots.forEach(function (shot) {
            return shot.loadImagesPage(1, 90);
          });
          this.get('model.mission').loadVideos();
          this.get('model.mission').loadPanoramas();
          this.get('model.mission').loadOrthoPhotoMaps();
          this.get('model.mission').loadDataFiles();
          this.get('model.mission').loadThreeDModels();
        } else if (tab === 'activityLogs') {
          this.get('model.mission').loadActivityLogs();
        } else {
          this.get('model.mission').loadPayments();
          this.get('model.mission').loadDropships();
        }
        this.set('activeTab', tab);
        this.set('view', 'thumbnail');
        this.set('selectedShot', null);
        this.set('shotMarkers', []);
        markers = this.get('model.mission.image_markers');
        this.set('model.filteredMapImages', markers);
        this.set('model.missionMarkers', markers.toArray());
        return window.scrollTo(0, 0);
      },
      expandAllShots() {
        return Ember.$.each(Ember.$('.mission-assets-shot-thumbnail .bucket-assets.collapse'), function (k, elem) {
          if (!Ember.$(elem).hasClass('in')) {
            Ember.$(elem).siblings('.bucket-header').find('a.header-left').click();
            return Ember.$(elem).find('.toggle-assets-link a span.see-all').click();
          }
        });
      },
      collapseAllShots() {
        return Ember.$.each(Ember.$('.mission-assets-shot-thumbnail .bucket-assets.collapse'), function (k, elem) {
          if (Ember.$(elem).hasClass('in')) {
            return Ember.$(elem).siblings('.bucket-header').find('a.header-left').trigger('click');
          }
        });
      },
      openFullView(shot) {
        var shotIndex;
        Ember.$('body').addClass('full-view-opened');
        this.set('view', 'full');
        if (shot) {
          this.set('currentShot', shot);
          this.set('currentPage', 1);
          shotIndex = 0;
          return this.get('mission.shots').forEach(function (_this) {
            return function (_shot) {
              if (_shot.id === shot.id) {
                _this.set('shotIndex', shotIndex);
              } else {
                return shotIndex++;
              }
            };
          }(this));
        } else {
          return this.set('currentShot', null);
        }
      },
      closeFullView() {
        Ember.$('body').removeClass('full-view-opened');
        this.set('view', 'thumbnail');
        this.set('assetMarker', null);
        this.set('currentAsset', null);
        this.set('assetIndex', null);
        this.set('shotIndex', null);
        return this.set('currentAssetNumber', null);
      },
      goToAsset(asset, index) {
        var currentAssetNumber, shotIndex;
        this.set('currentAsset', asset);
        this.set('currentShot', null);
        this.set('assetMarker', this.store.peekRecord('image_marker', asset.id));
        currentAssetNumber = index + 1;
        shotIndex = 0;
        this.set('assetIndex', index);
        this.get('mission.shots').forEach(function (_this) {
          return function (shot) {
            if (asset.get('shot.name') !== shot.get('name')) {
              currentAssetNumber += shot.get('images.length');
              return shotIndex++;
            } else {
              _this.set('shotIndex', shotIndex);
              _this.set('currentAssetNumber', currentAssetNumber);
            }
          };
        }(this));
        return this.send('openFullView');
      },
      setSubTab(name) {
        return this.set('activeSubTab', name);
      },
      scrollToElementId(elementId, rootElementClass) {
        var elementOffset, ref2, ref3, rootOffset;
        if (rootElementClass == null) {
          rootElementClass = null;
        }
        rootOffset = ((ref2 = Ember.$("." + rootElementClass)) != null ? (ref3 = ref2.offset()) != null ? ref3.top : void 0 : void 0) || 0;
        elementOffset = Ember.$("#" + elementId).offset().top;
        return Ember.$(document).scrollTop(elementOffset - rootOffset);
      },
      saveMission(model) {
        var newEstimatedPayouts;
        this.set('isEditingRefId', false);
        this.get('model.mission').set('estimated_pilot_payout', this.get('model.mission.estimated_payouts').findBy('payout_type', PILOT).amount);
        this.get('model.mission').set('estimated_editor_payout', this.get('model.mission.estimated_payouts').findBy('payout_type', EDITOR).amount);
        this.get('model.mission').set('estimated_analyst_payout', this.get('model.mission.estimated_payouts').findBy('payout_type', ANALYST).amount);
        this.get('model.mission').set('estimated_roof_measurement_payout', this.get('model.mission.estimated_payouts').findBy('payout_type', 'roof_measurement').amount);
        newEstimatedPayouts = [model.mission.estimated_pilot_payout_obj, model.mission.estimated_editor_payout_obj, model.mission.estimated_analyst_payout_obj, model.mission.estimated_roof_measurement_payout_obj];
        this.get('model.mission').set('estimated_payouts', newEstimatedPayouts);
        this.get('model.mission.estimated_payouts').save();
        return this.send('save', model);
      },
      reprocessPointivo() {
        return Ember.$.ajax({
          url: _environment.default.api.host + "/v1/admin/missions/" + this.get('model.mission.id') + "/reprocess_pointivo",
          method: 'POST',
          dataType: 'json',
          headers: this.get('sessionAccount.headers')
        }).then(function (_this) {
          return function (response) {
            _this.get('model.mission').store.pushPayload(response);
            return alert('Start pointivo reprocessing');
          };
        }(this))["catch"](function () {
          return alert('Failed to reprocess pointivo');
        });
      },
      duplicateMission() {
        var confirmDuplicate;
        confirmDuplicate = confirm("Are you sure you want to duplicate this mission?");
        if (confirmDuplicate) {
          return Ember.$.ajax({
            url: _environment.default.api.host + "/v1/admin/missions/" + this.model.mission.id + "/duplicate",
            type: 'POST',
            dataType: 'json',
            headers: this.get('sessionAccount.headers')
          }).then(function () {
            return function (response) {
              return window.open(window.location.origin + "/missions/" + response.mission_id + "/edit");
            };
          }(this), function () {
            return function (error) {
              return console.log('failed to duplicate', error);
            };
          }(this));
        }
      },
      saveLocation() {
        var data;
        if (this.model.mission.location.get('hasDirtyAttributes')) {
          data = this.model.mission.location.toJSON();
          data['mission_id'] = this.model.mission.id;
          data['client_id'] = this.model.mission.client.id;
          return Ember.$.ajax({
            url: _environment.default.api.host + "/v1/admin/locations/" + this.model.mission.location.id,
            headers: this.get('sessionAccount.headers'),
            type: 'PUT',
            data,
            dataType: 'json'
          });
        }
      },
      editRefId() {
        return this.set('isEditingRefId', true);
      },
      openAirspaceLink() {
        return Ember.$.ajax({
          url: _environment.default.api.host + "/v1/admin/missions/" + this.model.mission.id + "/generate_deeplink",
          type: 'POST',
          dataType: 'json',
          headers: this.get('sessionAccount.headers')
        }).then(function () {
          return function (response) {
            return window.open(response.link, '_blank').focus();
          };
        }(this), function () {
          return function () {
            return alert('Sorry, we were unable to generate ' + this.get('airspaceProvider') + ' link for you :(');
          };
        }(this));
      },
      changeEstimatedPilotPayoutCurrency(newCurrencyCode) {
        return this.model.mission.get('estimated_pilot_payout_obj').set('currency', this.model.currencies.find(function () {
          return function (currency) {
            return currency.code === newCurrencyCode;
          };
        }(this)));
      },
      changeEstimatedEditorPayoutCurrency(newCurrencyCode) {
        return this.model.mission.get('estimated_editor_payout_obj').set('currency', this.model.currencies.find(function () {
          return function (currency) {
            return currency.code === newCurrencyCode;
          };
        }(this)));
      },
      changeEstimatedAnalystPayoutCurrency(newCurrencyCode) {
        return this.model.mission.get('estimated_analyst_payout_obj').set('currency', this.model.currencies.find(function () {
          return function (currency) {
            return currency.code === newCurrencyCode;
          };
        }(this)));
      },
      changeEstimatedRoofMeasurementPayoutCurrency(newCurrencyCode) {
        return this.model.mission.get('estimated_roof_measurement_payout_obj').set('currency', this.model.currencies.find(function () {
          return function (currency) {
            return currency.code === newCurrencyCode;
          };
        }(this)));
      },
      setPayout(option) {
        const analyst = this.get('model.analysts').findBy('id', option);
        this.set('model.payout.pilot', analyst);
        const paymentProcessor = analyst.payment_processor === PAYPAL ? PAYPAL : `${STRIPE} (${this.get('model.defaultLegalEntity.name')})`;
        this.set('model.payout.payment_processor', paymentProcessor);
        this.set('model.payout.payee_name', analyst.fullName);
        this.set('model.payout.currency', this.get('model.mission.estimated_payouts').findBy('payout_type', ANALYST).currency);
      },
      showPayoutLineItemForm() {
        this.set('showPayoutLineItemForm', true);
        return this.send('initPayoutLineItem');
      },
      initPayoutLineItem() {
        var amount, currency, nextReason, nextReasonIndex, payoutTo, prefill;
        nextReasonIndex = this.get('nextReasonIndex');
        nextReason = _lineItemReasons.payoutLineItemReasons[nextReasonIndex]['value'];
        payoutTo = this.get('model.payout.payout_to');
        prefill = 0;
        switch (payoutTo) {
          case PILOT:
            prefill = this.get('model.mission.estimated_pilot_payout_obj').get('amount');
            break;
          case ANALYST:
            prefill = this.get('model.mission.estimated_analyst_payout_obj').get('amount');
            break;
          case EDITOR:
            prefill = this.get('model.mission.estimated_editor_payout_obj').get('amount');
        }
        this.set('payoutLineItemLaborPrefill', prefill);
        currency = this.get('model.mission').get('currency');
        switch (payoutTo) {
          case PILOT:
            currency = this.get('model.mission.estimated_pilot_payout_obj').get('currency');
            break;
          case ANALYST:
            currency = this.get('model.mission.estimated_analyst_payout_obj').get('currency');
            break;
          case EDITOR:
            currency = this.get('model.mission.estimated_editor_payout_obj').get('currency');
        }
        amount = nextReason === 'labor' ? prefill : 0;
        this.set('lineItem', this.store.createRecord('payout_line_item', {
          saved_payoutable_type: 'missions',
          saved_payoutable_id: this.get('model.mission.id'),
          reason: nextReason,
          amount,
          currency
        }));
        return this.set('nextReasonIndex', (nextReasonIndex + 1) % _lineItemReasons.payoutLineItemReasons.length);
      },
      addPayoutLineItem(continueAdding) {
        var isFirstLineItem;
        if (continueAdding == null) {
          continueAdding = false;
        }
        isFirstLineItem = this.get('model.payout.payout_line_items.length') === 0;
        this.get('model.payout.payout_line_items').addObject(this.get('lineItem'));
        this.get('model.payout').set('amount', (+this.get('model.payout.amount') || 0) + +this.get('lineItem.amount'));
        if (isFirstLineItem) {
          this.get('model.payout').set('currency', this.get('lineItem.currency'));
        }
        if (continueAdding) {
          this.set('lineItem', null);
          return this.send('initPayoutLineItem');
        } else {
          return this.send('clearPayoutLineItem');
        }
      },
      confirmAddLineItem(continueAdding) {
        if (continueAdding == null) {
          continueAdding = false;
        }
        if (this.get('lineItem.amount') / 100 > this.get('maxPayoutItemAmount')) {
          this.set('showConfirmPayoutModal', true);
        } else {
          return this.send('addPayoutLineItem');
        }
      },
      closeConfirmationModal() {
        this.set('showConfirmPayoutModal', false);
        return window.scrollTo(0, 5000);
      },
      allowAddLineItem(continueAdding) {
        if (continueAdding == null) {
          continueAdding = false;
        }
        this.send('addPayoutLineItem');
        this.set('showConfirmPayoutModal', false);
        return window.scrollTo(0, 5000);
      },
      addAnotherPayoutLineItem() {
        return this.send('addPayoutLineItem', true);
      },
      clearPayoutLineItem() {
        this.set('lineItem', null);
        return this.set('showPayoutLineItemForm', false);
      },
      clearPayoutLineItems() {
        this.get('model.payout.payout_line_items').toArray().forEach(function (item) {
          return item.destroyRecord();
        });
        this.get('model.payout').set('amount', 0);
        this.set('nextReasonIndex', 0);
        return this.set('showPayoutLineItemForm', false);
      },
      toggleShowCaptureGroupModal() {
        this.set('showCaptureGroupModal', !this.get('showCaptureGroupModal'));
      },
      toggleShowWorkOrderModal() {
        this.set('showWorkOrderModal', !this.get('showWorkOrderModal'));
      },
      destroyAirspaceCertificate(airspaceCertificate) {
        return this.store.adapterFor('airspace-certificate').destroy(airspaceCertificate.id, this.get('sessionAccount.headers')).then(() => {
          this.get('model.mission.airspace_certificates').removeObject(airspaceCertificate);
          this.flashMessages.success('Airspace Certificate was deleted!');
        }).catch(response => {
          this.flashMessages.error('Failed to delete Airspace Certificate');
          (0, _sendErrorToSentry.default)((0, _getErrorMessageFromResponse.default)(response));
        });
      }
    }
  });
});