define("admin/controllers/missions/new", ["exports", "admin/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    defaultLocationId: null,
    sessionAccount: Ember.inject.service(),
    store: Ember.inject.service(),
    queryParams: ['activeTab', 'importMode', 'defaultLocationId'],
    activeTab: 'newMission',
    importMode: 'standard',
    _initializedFromClient: false,
    disableNotifications: Ember.computed('activeTab', 'importMode', {
      get() {
        if (!this.get('_initializedFromClient')) {
          if (this.get('activeTab') === 'newMission' && this.get('importMode') === 'standard') {
            this.get('model.mission').setProperties({
              disable_notifications: this.get('model.client.disable_emails')
            });
          } else {
            this.get('model.mission').setProperties({
              disable_notifications: true
            });
          }
          // eslint-disable-next-line ember/no-side-effects
          this.set('_initializedFromClient', true);
        }
        return this.get('model.mission').get('disable_notifications');
      },
      set(key, value) {
        this.get('model.mission').setProperties({
          disable_notifications: value
        });
        return value;
      }
    }),
    resetNotificationState: Ember.observer('activeTab', 'importMode', function () {
      this._initializedFromClient = false;
      this.notifyPropertyChange('disableNotifications');
    }),
    acceptRestrictedAirspace: Ember.computed('importMode', function () {
      return this.get('importMode') !== 'standard';
    }),
    testUserId: Ember.computed('ENV.test_user', function () {
      return "" + _environment.default.test_user;
    }),
    formTitle: Ember.computed("importMode", function () {
      if (this.get('importMode') === 'standard' || this.get('importMode') === null) {
        return 'Create Standard Missions';
      } else {
        return 'Create Training Missions';
      }
    }),
    showLocationWorkOrders: Ember.computed('model.mission.location', function () {
      return this.get('model.mission.location') && this.get('model.mission.location.work_orders').toArray().length > 0;
    }),
    workOrderOptions: Ember.computed('model.{workOrders,mission.location}', function () {
      let location = this.get('model.mission.location.id');
      return this.get('model.workOrders').filterBy('mission_location_id', location).map(workOrder => {
        return Ember.Object.create({
          label: `${workOrder.number} - ${workOrder.site.formatted_address}`,
          value: workOrder.id
        });
      });
    }),
    accountRep: Ember.observer('model.mission.package', function () {
      if (this.get('model.mission.package.accountRep') && !this.get('model.mission.accountRep')) {
        return this.set('model.mission.accountRep', this.get('model.mission.package.accountRep'));
      }
    }),
    defaultPackageObserver: Ember.observer('model.mission.location', function () {
      let defaultPackageId = this.get('model.mission.location.last_package_id');
      if (defaultPackageId) {
        let defaultPackage = this.store.peekRecord('package', defaultPackageId);
        if (defaultPackage) {
          return this.set('model.mission.package', defaultPackage);
        }
      }
    }),
    defaultLocationSetter: Ember.observer('defaultLocationId', 'model', function () {
      var defaultLocation;
      if (this.defaultLocationId && this.get('model')) {
        defaultLocation = this.get('model.locations').filter(function (_this) {
          return function (location) {
            return location.id === _this.defaultLocationId;
          };
        }(this)).lastObject;
        if (defaultLocation) {
          return this.set('model.mission.location', defaultLocation);
        }
      }
    }),
    actions: {
      setTab(tab) {
        return this.set('activeTab', tab);
      },
      setMode(mode) {
        return this.set('importMode', mode);
      },
      setNotifications(value) {
        this.set('disableNotifications', value);
      },
      uploadCsv() {
        var formData, hasErrors, inputAttachment, selectAdmin, selectPackage;
        hasErrors = false;
        selectPackage = Ember.$('#import-csv-form select[name=mission_package_id]');
        selectAdmin = Ember.$('#import-csv-form select[name=account_rep_id]');
        inputAttachment = Ember.$('#import-csv-form input[name=attachment]');
        if (selectPackage.val() === null) {
          hasErrors = true;
          selectPackage.addClass('error-border');
          Ember.$('#csv-package-error').removeClass('hidden');
        } else {
          selectPackage.removeClass('error-border');
          Ember.$('#csv-package-error').addClass('hidden');
        }
        if (selectAdmin.val() === null && this.get('importMode') === 'standard') {
          hasErrors = true;
          selectAdmin.addClass('error-border');
          Ember.$('#csv-rep-error').removeClass('hidden');
        } else {
          selectAdmin.removeClass('error-border');
          Ember.$('#csv-rep-error').addClass('hidden');
        }
        if (inputAttachment.val() === "") {
          hasErrors = true;
          inputAttachment.addClass('error-border');
          Ember.$('#csv-file-error').removeClass('hidden');
        } else {
          inputAttachment.removeClass('error-border');
          Ember.$('#csv-file-error').addClass('hidden');
        }
        if (hasErrors) {
          return;
        }
        formData = new FormData(Ember.$('#import-csv-form')[0]);
        formData.set('csv_type', this.get('importMode') || 'standard');
        return Ember.$.ajax({
          url: _environment.default.api.host + "/v1/admin/client_mission_csvs",
          type: 'POST',
          contentType: false,
          csv_type: this.get('importMode') || 'standard',
          cache: false,
          processData: false,
          headers: this.get('sessionAccount.headers'),
          data: formData,
          enctype: 'multipart/form-data'
        }).then(function (_this) {
          return function () {
            return window.location = "/clients/" + _this.get('model.client.id') + "?activeTab=imports";
          };
        }(this), function (response) {
          if (response.status === 422) {
            if (response.responseJSON.error === "already exists") {
              return alert("Error: This Capture Group name has already been taken");
            } else if (response.responseJSON.error === "no client organization") {
              return alert("Error: The client must belong to an organization to create a Capture Group");
            } else if (response.responseJSON.error === "wind is not supported") {
              return alert("Error: Creating a Capture Group for Wind is not supported");
            } else {
              return alert("Error: make sure all fields are filled out and the file is a csv with the correct format");
            }
          } else {
            return alert("Error: internal server error, try again later");
          }
        });
      },
      saveLocation() {
        var data;
        if (this.model.mission.location.get('hasDirtyAttributes')) {
          data = this.model.mission.location.toJSON();
          data['mission_id'] = this.model.mission.id;
          data['client_id'] = this.model.mission.client.id;
          Ember.$.ajax({
            url: _environment.default.api.host + "/v1/admin/locations/" + this.model.mission.location.id,
            headers: this.get('sessionAccount.headers'),
            type: 'PUT',
            data
          });
        }
        return false;
      },
      setSiteInspection(event) {
        let si = this.get('model.workOrders').findBy('number', event);
        this.set('model.mission.site_inspection', si);
      }
    }
  });
});