define("admin/data/admin_roles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = [{
    value: "mission_csv_exporter",
    label: "Can use the CSV export tool in search pages"
  }, {
    value: "auth0_access",
    label: "Can use Login as Client/Pilot"
  }, {
    value: "ops_manager",
    label: "Has Access to Admin App"
  }, {
    value: "roles_manager",
    label: "Has Access to Admin Management Tool"
  }, {
    value: "bulk_mission_update_manager",
    label: "Has Access to Bulk Mission Update Tool"
  }, {
    value: "payout_manager",
    label: "Has Access to Payout Tool"
  }, {
    value: "account_rep",
    label: "Visible in `Account Reps` list"
  }, {
    value: "operation_rep",
    label: "Visible in `Operations Reps` list"
  }, {
    value: "production_rep",
    label: "Visible in `Production Reps` list"
  }, {
    value: 'finance_manager',
    label: 'is Finance Manager'
  }];
});