define("admin/components/mission/assets/shot-base", ["exports", "admin/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    sessionAccount: Ember.inject.service(),
    store: Ember.inject.service(),
    assetTypesToBackendPath: Object.freeze({
      "pano": "panorama",
      "orthomosaic": "ortho",
      "3D model": "three_d_model"
    }),
    isOpen: false,
    isExpanded: false,
    showAssetsCount: null,
    page: 1,
    showToTopLinkAfterAssets: 60,
    playingVideos: Ember.A([]),
    videosReady: Ember.computed('shot.videos.@each.source_type', function () {
      return this.get('shot.videos').filterBy('processing_status', 'ready').sortBy('record.source_type', 'record.id:desc');
    }),
    dataFilesReady: Ember.computed('shot.data_files.@each.source_type', function () {
      return this.get('shot.data_files').filterBy('processing_status', 'ready').sortBy('record.source_type', 'record.id:desc');
    }),
    threeDModelsReady: Ember.computed('shot.three_d_models', function () {
      return this.get('shot.three_d_models').filterBy('processing_status', 'ready').sortBy('record.id:desc');
    }),
    assetsReady: Ember.computed('shot.images.@each.source_type', 'videosReady', 'isExpanded', 'page', 'playingVideos', 'selectedQCFilters', function () {
      const images = this.filterImagesByQCFilters(this.get('shot.images')).filterBy('processing_status', 'ready').sort((a, b) => a.takenAt.toDate() - b.takenAt.toDate());
      const videos = this.get('videosReady').filter(v => !this.get('playingVideos').includes(v));
      let scope = images.pushObjects(videos);
      if (!this.isExpanded && this.get('showAssetsCount')) {
        const begin = (this.get('page') - 1) * this.get('showAssetsCount');
        scope = scope.slice(begin, begin + this.get('showAssetsCount'));
        if (this.setShowToTopLinkAction) {
          this.setShowToTopLinkAction(scope.length > this.get('showToTopLinkAfterAssets') ? true : false);
        }
      }
      return scope;
    }),
    assetsProcessing: Ember.computed('shot.{images.@each.processing,videos.@each.processing,data_files.@each.processing}', function () {
      const deliverables = this.get('shot.images').filterBy('processing');
      const videos = this.get('shot.videos').filterBy('processing');
      const data_files = this.get('shot.data_files').filterBy('processing');
      deliverables.pushObjects(data_files);
      return deliverables.pushObjects(videos);
    }),
    showMoreAssetsCount: Ember.computed('shot.images.length', 'showAssetsCount;', function () {
      if (this.get('showAssetsCount')) {
        return this.get('shot.totalAssetCount') - this.get('showAssetsCount');
      } else {
        return 0;
      }
    }),
    shotWarnings: Ember.computed('shot.{hasMissingGps,hasLowResolution}', function () {
      let warnings = Ember.A([]);
      if (this.get('shot.hasMissingGps')) {
        warnings.pushObject('Missing GPS Meta Data');
      }
      if (this.get('shot.hasLowResolution')) {
        warnings.pushObject('Low Resolution');
      }
      if (this.get('shot.hasMissingRtk')) {
        warnings.pushObject('Missing RTK Meta Data');
      }
      return warnings.join(', ');
    }),
    partialShotImagesSelected: Ember.computed('selectedShotAssetsNumbers.length', 'allShotImagesSelected', function () {
      return this.get('selectedShotAssetsNumbers.length') > 0 && !this.get('allShotImagesSelected');
    }),
    allShotImagesSelected: Ember.computed('selectedShotAssetsNumbers.length', 'shotImagesNumbers.length', function () {
      return this.get('selectedShotAssetsNumbers.length') === this.get('shotImagesNumbers.length');
    }),
    shotImagesNumbers: Ember.computed('assetsReady.length', 'shot', function () {
      return this.get('assetsReady').getEach('id');
    }),
    selectedShotAssetsNumbers: Ember.computed('selectedAssetsNumbers.length', 'shot', function () {
      return this.get('shotImagesNumbers').filter(number => this.get('selectedAssetsNumbers').includes(number));
    }),
    filterImagesByQCFilters(images) {
      const selectedQCFilters = this.get('selectedQCFilters');
      if (!selectedQCFilters?.length) return images;
      if (selectedQCFilters.includes('qc_issued_only')) {
        return images.filter(image => image.get('is_low_resolution') || image.get('missing_gps_info') || image.get('missing_rtk_data') || image.get('is_blurry_image'));
      } else {
        return images.filter(image => selectedQCFilters.some(filter => image.get(filter)));
      }
    },
    actions: {
      promoteAll() {
        const request = this.get('shot').promoteAssets().then(() => {
          this.get('shot').loadImagesPage(1, 90);
          this.get('shot.mission').loadVideos();
          this.get('shot.mission').loadPanoramas();
          this.get('shot.mission').loadDataFiles();
          return this.get('shot.mission').loadOrthoPhotoMaps();
        });
        if (this.get('onBusy')) {
          this.get('onBusy')(request);
        }
        return request;
      },
      unpromoteAll() {
        const request = this.get('shot').unpromoteAssets().then(() => {
          this.get('shot').loadImagesPage(1, 90);
          this.get('shot.mission').loadVideos();
          this.get('shot.mission').loadPanoramas();
          this.get('shot.mission').loadDataFiles();
          return this.get('shot.mission').loadOrthoPhotoMaps();
        });
        if (this.get('onBusy')) {
          this.get('onBusy')(request);
        }
        return request;
      },
      downloadShotArchive() {
        return this.get('shot').downloadImages().then(() => alert("The zip file is now generating, and will be sent to your email"));
      },
      transferDeliverables() {
        return this.get('shot').transferDeliverables().then(() => alert("Transfer is scheduled")).catch(e => {
          alert(e.errors[0]);
        });
      },
      reuploadOrtho(shot) {
        if (!confirm(`We'll automatically delete the existing ortho in the bucket before uploading another`)) return;
        const ortho = shot.ortho;
        const request = ortho.destroyRecord().then(() => {
          this.get('assetsReady').removeObject(ortho);
          this.get('shot.ortho_photo_maps').removeObject(ortho);
          shot.reload();
          this.get('shot').set('hasOrthos', false);
          return Ember.$('#upload-ortho-' + shot.get('id'))[0].click();
        }).catch(() => {
          return alert('There was an issue deleting this ortho');
        });
        if (this.get('onBusy')) {
          this.get('onBusy')(request);
        }
        return request;
      },
      reuploadDSMOrtho(shot) {
        if (!confirm(`We'll automatically delete the existing DSM ortho in the bucket before uploading another`)) return;
        const ortho = shot.ortho;
        const request = this.store.adapterFor('ortho-photo-map').deleteDSMOrtho(shot.get('mission.id'), ortho.get('id'), this.get('sessionAccount.headers')).then(() => {
          shot.reload();
          return Ember.$('#upload-ortho-dsm-' + shot.get('id'))[0].click();
        }).catch(() => {
          return alert('There was an issue deleting this ortho');
        });
        return request;
      },
      reuploadThreeDModel(shot) {
        if (!confirm(`We'll automatically delete the existing 3D model in the bucket before uploading another`)) return;
        const model = shot.threeDModel;
        const request = model.destroyRecord().then(() => {
          this.get('shot.three_d_models').removeObject(model);
          shot.reload();
          this.get('shot').set('hasThreeDModel', false);
          return Ember.$('#upload-three-d-model-' + shot.get('id'))[0].click();
        }).catch(error => {
          console.error(error);
          return alert('There was an issue deleting this 3D model');
        });
        if (this.get('onBusy')) {
          this.get('onBusy')(request);
        }
        return request;
      },
      resetAsset(id, assetType, forceStitcher = null, quality = null) {
        if (!confirm(`Delete ${assetType} and reprocess with ${forceStitcher}?`)) {
          return;
        }
        const missionId = this.get('shot.mission.id');
        const assetBackendPath = this.get('assetTypesToBackendPath')[assetType];
        return Ember.$.ajax({
          url: `${_environment.default.api.host}/v1/admin/missions/${missionId}/shots/${id}/${assetBackendPath}/restart`,
          method: 'POST',
          dataType: 'json',
          data: {
            force_stitcher: forceStitcher?.toLowerCase(),
            quality
          },
          headers: this.get('sessionAccount.headers')
        }).then(() => {
          return location.reload();
        }, () => {
          return alert('Could not send request');
        });
      },
      processAsset(id, assetType) {
        if (!confirm(`Process ${assetType}?`)) {
          return;
        }
        const missionId = this.get('shot.mission.id');
        const assetBackendPath = this.get('assetTypesToBackendPath')[assetType];
        return Ember.$.ajax({
          url: `${_environment.default.api.host}/v1/admin/missions/${missionId}/shots/${id}/${assetBackendPath}/stitch`,
          method: 'POST',
          dataType: 'json',
          headers: this.get('sessionAccount.headers')
        }).then(() => {
          return location.reload();
        }, () => {
          return alert('Could not send request');
        });
      },
      postProcess(id, slug) {
        if (!confirm(`Re process ${slug}?`)) {
          return;
        }
        const missionId = this.get('shot.mission.id');
        return Ember.$.ajax({
          url: `${_environment.default.api.host}/v1/admin/missions/${missionId}/shots/${id}/post_processes`,
          method: 'POST',
          dataType: 'json',
          data: {
            slug
          },
          headers: this.get('sessionAccount.headers')
        }).then(() => {
          return location.reload();
        }, () => {
          return alert('Could not send request');
        });
      },
      addAsset(file) {
        return this.onfileadd(file, {
          "shot_id": this.get('shot.id')
        });
      },
      addOrtho(file) {
        return this.onfileadd(file, {
          "shot_id": this.get('shot.id'),
          "deliverable_type": "ortho_photo_map"
        });
      },
      addOrthoDSM(file) {
        return this.onfileadd(file, {
          "shot_id": this.get('shot.id'),
          "deliverable_type": "ortho_dsm_photo_map"
        });
      },
      addThreeDModel(file) {
        return this.onfileadd(file, {
          "shot_id": this.get('shot.id'),
          "deliverable_type": "three_d_model"
        });
      },
      removeAsset(file, payload) {
        this.onfileremove(file, payload);
      },
      startUpload(uploader) {
        return this.onstartupload(uploader);
      },
      toggleShot(shot) {
        if (this.isOpen) {
          this.removeMarkersFromMap(shot);
        } else {
          this.addMarkersToMap(shot);
        }
        this.toggleProperty('isOpen');
      },
      downloadAsset(asset_url) {
        return window.location = asset_url;
      },
      editPanorama(panorama) {
        return window.open(`${_environment.default.panoEditor.host}/p/${panorama.get('accessKey')}/edit`);
      },
      deleteAsset(asset) {
        if (asset && window.confirm('Are you sure? This will permanently remove this asset.')) {
          const request = asset.destroyRecord().then(() => {
            return this.get('assetsReady').removeObject(asset);
          }).catch(() => {
            return alert('There was an issue deleting this asset');
          });
          if (this.get('onBusy')) {
            this.get('onBusy')(request);
          }
          return request;
        }
      },
      toggleSourceType(asset) {
        if (asset.get('source_type') === 'raw') {
          asset.set('source_type', 'edit');
        } else {
          asset.set('source_type', 'raw');
        }
        const request = asset.save().then(() => {
          //Noop
        }, () => {
          if (asset.get('source_type') === 'raw') {
            asset.set('source_type', 'edit');
          } else {
            asset.set('source_type', 'raw');
          }
          return alert(`There was an error updating asset ${asset.get('id')}`);
        });
        if (this.get('onBusy')) {
          this.get('onBusy')(request);
        }
        return request;
      },
      shareShareable(shareable, deferred) {
        return this.shareCreateAction(shareable, deferred);
      },
      toggleFullscreenOrthoModal(ortho) {
        return this.toggleFullscreenOrthoModalAction(ortho);
      },
      toggleAssets() {
        return this.toggleProperty('isExpanded');
      },
      playVideo(video) {
        this.set('playingVideos', this.get('playingVideos') + video);
      },
      selectAsset(asset, event) {
        event.stopPropagation();
        this.onAssetsSelect([asset], !this.get('selectedShotAssetsNumbers').includes(asset.get('id')));
      },
      selectAllShotAssets() {
        this.onAssetsSelect(this.get('assetsReady'), !this.get('allShotImagesSelected'));
      }
    }
  });
});