define("admin/components/missions/modals/bulk-action/payout-update-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    currencies: null,
    formObject: null,
    init() {
      this._super(...arguments);
      this._initializeCurrencies();
      this._initializeFormObject();
    },
    _initializeCurrencies() {
      if (!this.currencies || this.currencies.length === 0) {
        this.set('currencies', []);
      }
    },
    _initializeFormObject() {
      if (!this.formObject) {
        this.set('formObject', this._createDefaultFormObject());
      }
    },
    _createDefaultFormObject() {
      return Ember.Object.create({
        currency: this.selectedCurrency,
        estimated_ppo: null,
        updatePPO: false
      });
    },
    dangerousMissionsCount: Ember.computed('selectedMissions.@each.status', function () {
      if (!this.selectedMissions) return 0;
      const dangerousStatuses = ['invoice_needed', 'invoiced', 'complete'];
      return this.selectedMissions.filter(mission => dangerousStatuses.includes(mission.get('status'))).length;
    }),
    hasDangerousMissions: Ember.computed('dangerousMissionsCount', function () {
      return this.get('dangerousMissionsCount') > 0;
    }),
    actions: {
      toggleUpdatePPO() {
        this.toggleProperty('formObject.updatePPO');
      },
      handleCurrencyChange(value) {
        this.set('formObject.currency', value);
      },
      submitPayoutUpdate() {
        this.onSubmitPPO(this.formObject);
      },
      closeModal() {
        this.onClose();
      }
    }
  });
});