define("admin/components/reusable/airspace-certificates/link-to-edit-airspace-certificate-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    tagName: '',
    missionId: null,
    airspaceCertificateId: null,
    url: Ember.computed('missionId', function () {
      return `/missions/${this.get('missionId')}?airspace_certificate=${this.get('airspaceCertificateId')}`;
    })
  });
});