define("admin/components/mission-status-update-button", ["exports", "admin/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const MissionStatusUpdateButtonComponent = Ember.Component.extend({
    session: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    type: 'submit',
    classNameBindings: ['isHidden:hide'],
    isHidden: false,
    isSendingRequest: false,
    confirmAndAutoDispatchButton: Ember.computed('model.{pilot_invitations_dispatch,package.auto_dispatch_enabled,isReshoot}', function () {
      return this.get('model.package.auto_dispatch_enabled') && this.get('model.pilot_invitations_dispatch') === null && !this.get('model.isReshoot');
    }),
    statusDisabledTooltip: Ember.computed('model.{ready_for_auto_dispatch,status}', 'nextStatus', function () {
      if (!this.get('model.ready_for_auto_dispatch') && this.get('model.status') === 'created' && this.get('confirmAndAutoDispatchButton')) {
        return 'You must set an account rep and estimated pilot payout before proceeding. Please check below';
      }
      if (['Invoiced', 'Complete'].includes(this.get('nextStatus')) && ['invoice_needed', 'invoiced'].includes(this.get('model.status')) && !this.get('admin.isFinanceManager')) {
        return `You do not have permission to move a mission to ${this.get('nextStatus')}.`;
      }
      return '';
    }),
    goToShedulingCall: Ember.computed('model.{status,scheduling_requirement,scheduling_contact,recalculateStatus}', function () {
      if (this.get('model.status') === 'created') {
        if (this.get('model.scheduling_requirement') === 'call_to_schedule') {
          if (this.get('model.scheduling_contact.name') && this.get('model.scheduling_contact.phone')) {
            return !this.get('model').changedAttributes().scheduling_requirement;
          }
        }
      }
      return false;
    }),
    // eslint-disable-next-line ember/new-module-imports,ember/no-observers
    statusObserver: Ember.observer('model.status', function () {
      switch (this.get('model.status')) {
        case 'created':
        case 'scheduling_call':
        case 'canceled':
        case 'pilot_accepted':
        case 'flight_complete':
        case 'assets_uploaded':
        case 'assets_classified':
        case 'in_production':
        case 'awaiting_payment':
        case 'invoice_needed':
        case 'invoiced':
          this.set('isHidden', false);
          return this.set('name', 'next step');
        default:
          return this.set('isHidden', true);
      }
    }),
    nextStatus: Ember.computed('model.{status,client.invoiceable,skipInProduction,deliverable_work_types.length,recalculateStatus}', 'goToSchedulingCall', function () {
      switch (this.get('model.status')) {
        case 'created':
          if (this.get('model.client_upload')) {
            return 'Upload Required';
          } else if (this.get('goToShedulingCall')) {
            return 'Call to Schedule';
          } else if (this.get('confirmAndAutoDispatchButton')) {
            return 'Confirm and auto-dispatch';
          } else {
            return 'Confirmed';
          }
        case 'scheduling_call':
          if (this.get('confirmAndAutoDispatchButton')) {
            return 'Confirm and auto-dispatch';
          } else {
            return 'Confirmed';
          }
        case 'pilot_accepted':
          return 'Flight Complete';
        case 'flight_complete':
        case 'upload_required':
          return 'Assets Uploaded';
        case 'assets_uploaded':
          return 'Processing Shots';
        case 'assets_classified':
          if (this.get('model.skipInProduction')) {
            if (this.get('model.client.invoiceable')) {
              return 'Invoice Needed';
            } else {
              return 'Awaiting Payment';
            }
          } else {
            return 'In Production';
          }
        case 'in_production':
          if (this.get('model.client.invoiceable')) {
            return 'Invoice Needed';
          } else {
            return 'Awaiting Payment';
          }
        case 'invoice_needed':
          return 'Invoiced';
        case 'invoiced':
          return 'Complete';
        case 'awaiting_payment':
          return 'Complete';
        case 'canceled':
          return 'confirmed';
      }
      return null;
    }),
    click(e) {
      e.preventDefault();
      e.stopPropagation();
      if (this.get('model').get('isOnHold') || this.get('statusDisabledTooltip')) {
        return;
      }
      const confirm = this.get('isSendingRequest') ? false : window.confirm("Are you sure you want to change the status?");
      if (confirm) {
        const _this = this;
        const _model = this.get('model');
        const headers = this.get('sessionAccount.headers');
        _this.set('isSendingRequest', true);
        const request = Ember.$.ajax({
          url: `${_environment.default.api.host}/v1/admin/missions/${_model.id}/status`,
          type: 'PATCH',
          dataType: 'json',
          headers
        }).then(() => _model.reload().then(() => _this.set('isSendingRequest', false), function (response) {
          let errors = "";
          response.responseJSON.errors.forEach(e => {
            return errors = errors + e.detail + "\n";
          });
          alert(errors);
          return _this.set('isSendingRequest', false);
        }), function (response) {
          let errors = "";
          response.responseJSON.errors.forEach(e => {
            return errors = errors + e.detail + "\n";
          });
          alert(errors);
          return _this.set('isSendingRequest', false);
        });
        if (this.get('onBusy')) {
          this.get('onBusy')(request);
        }
        return request;
      }
    }
  });
  var _default = _exports.default = MissionStatusUpdateButtonComponent;
});