define("admin/templates/components/reusable/airspace-certificates/link-to-new-airspace-certificate-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "UTSbiS7s",
    "block": "{\"symbols\":[],\"statements\":[[10,\"a\"],[14,0,\"btn btn-secondary btn-sm\"],[15,6,[30,[36,1],[[35,0]],null]],[12],[2,\"\\n  ADD CERTIFICATES OF AUTHORIZATION OR WAIVER\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"url\",\"link-to-react-app\"]}",
    "meta": {
      "moduleName": "admin/templates/components/reusable/airspace-certificates/link-to-new-airspace-certificate-form.hbs"
    }
  });
});