define("admin/data/line-item-reasons", ["exports", "admin/services/titleizer"], function (_exports, _titleizer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.priceAdjustments = _exports.payoutLineItemReasons = _exports.displayFees = _exports.displayDiscounts = _exports.default = _exports.coreFees = _exports.coreDiscounts = void 0;
  const coreFees = _exports.coreFees = ['additional_assets', 'additional_production_work', 'call_to_schedule', 'expedited_flight', 'flight_app_issue', 'large_property_size', 'late_cancellation', 'pilot_turned_away_on_site', 'property_access_issue', 'additional_time_on_site', 'roof_measurements', 'airspace_authorization'];
  const displayFees = _exports.displayFees = coreFees.map(reason => {
    return {
      name: 'Fee - ' + (0, _titleizer.default)(reason),
      value: reason
    };
  });
  const coreDiscounts = _exports.coreDiscounts = ['courtesy', 'mission_execution', 'reshoot', 'refund', 'promotion', 'scheduling_delay', 'uncapturable_assets', 'reduced_time_on_site'];
  const displayDiscounts = _exports.displayDiscounts = coreDiscounts.map(reason => {
    return {
      name: 'Discount - ' + (0, _titleizer.default)(reason),
      value: reason
    };
  });
  const priceAdjustments = _exports.priceAdjustments = [{
    name: 'Other - Price Adjustment (Increase)',
    value: 'price_adjustment_increase'
  }, {
    name: 'Other - Price Adjustment (Decrease)',
    value: 'price_adjustment_decrease'
  }];
  let reasons = displayFees.concat(displayDiscounts, priceAdjustments);
  const payoutLineItemReasons = _exports.payoutLineItemReasons = [{
    value: 'labor',
    label: 'Labor'
  }, {
    value: 'standby_time',
    label: 'Standby Time'
  }, {
    value: 'mobilization',
    label: 'Mobilization'
  }, {
    value: 'tax',
    label: 'Tax'
  }, {
    value: 'other',
    label: 'Other'
  }];
  var _default = _exports.default = reasons;
});