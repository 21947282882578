define("admin/models/airspace_certificate", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberData.default.Model.extend({
    certificate_type: _emberData.default.attr('string'),
    doc_identifier: _emberData.default.attr('string'),
    altitude: _emberData.default.attr('number'),
    start_date: _emberData.default.attr('date'),
    end_date: _emberData.default.attr('date'),
    special_provisions: _emberData.default.attr('string'),
    remote_url: _emberData.default.attr('string'),
    filename: _emberData.default.attr('string'),
    attachment_date: _emberData.default.attr('date')
  });
});