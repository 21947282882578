define("admin/controllers/clients/organization/modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    // eslint-disable-next-line no-dupe-keys
    showSubscriptionLevel: false,
    showSubscriptionLevelDefaultValue: true,
    subscriptionLevels: Ember.computed(function () {
      return {
        Buildings: [Ember.Object.create({
          value: 'Basic',
          label: 'Basic'
        }), Ember.Object.create({
          value: 'Enterprise',
          label: 'Enterprise'
        })],
        Solar: [Ember.Object.create({
          value: 'Standard',
          label: 'Standard'
        }), Ember.Object.create({
          value: 'Pro',
          label: 'Pro'
        })]
      };
    }),
    insightsAccessValues: Ember.computed(function () {
      return [Ember.Object.create({
        label: 'None',
        value: 'None'
      }), Ember.Object.create({
        label: 'AEC',
        value: 'AEC'
      }), Ember.Object.create({
        label: 'Property',
        value: 'Buildings'
      }), Ember.Object.create({
        label: 'Solar',
        value: 'Solar'
      }), Ember.Object.create({
        label: 'Wind',
        value: 'Wind'
      }), Ember.Object.create({
        label: 'Telecom',
        value: 'Telecom'
      })];
    }),
    willDestroy() {
      this.model.organization.rollbackAttributes();
    },
    // eslint-disable-next-line no-dupe-keys
    showSubscriptionLevel: Ember.computed('model.client.organization.insight_access', function () {
      // eslint-disable-next-line ember/no-side-effects
      this.set('showSubscriptionLevelDefaultValue', false);
      this.send('showSubscriptionLevel', this.model.organization.insight_access);
      // eslint-disable-next-line ember/no-side-effects
      this.set('showSubscriptionLevelDefaultValue', true);
      return this.get('showSubscriptionLevel');
    }),
    actions: {
      showSubscriptionLevel(option) {
        const subscriptionLevels = this.get('subscriptionLevels');
        const subscriptionLevelValues = subscriptionLevels[option];
        this.set('model.organization.insight_access', option);
        if (subscriptionLevelValues == undefined) {
          this.set('showSubscriptionLevel', false);
          this.set('model.organization.subscription_level', '');
        } else {
          this.set('showSubscriptionLevel', true);
          this.set('subscriptionLevelValues', subscriptionLevelValues);
          if (this.get('showSubscriptionLevelDefaultValue')) {
            this.set('model.organization.subscription_level', subscriptionLevelValues[0]['value']);
          }
        }
      }
    }
  });
});