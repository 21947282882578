define("admin/data/allowed-extensions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.threeDModelExtensions = _exports.orthoExtensions = _exports.logFileExtensions = _exports.extendedList = _exports.dataFileExtensions = _exports.dataFileExtensionMatcher = _exports.baseList = _exports.allowedTypeMatcher = void 0;
  const baseList = _exports.baseList = ['mov', 'mp4', 'iiq', 'jpg', 'jpeg', 'tif', 'tiff', 'png', 'zip'];
  const extendedList = _exports.extendedList = [...baseList, 'dng'];
  const orthoExtensions = _exports.orthoExtensions = ['tif', 'tiff'];
  const threeDModelExtensions = _exports.threeDModelExtensions = ['3tz'];
  const dataFileExtensions = _exports.dataFileExtensions = ['blk', 'e57', 'las', 'ats'];
  const logFileExtensions = _exports.logFileExtensions = ['txt'];
  const allowedTypeMatcher = _exports.allowedTypeMatcher = /video|image|zip|msword|pdf|document|doc|ats|ms-word|text/;
  const dataFileExtensionMatcher = _exports.dataFileExtensionMatcher = new RegExp(`\\.(${dataFileExtensions.join('|')})$`, 'i');
});