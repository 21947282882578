define("admin/templates/components/reusable/airspace-certificates/link-to-edit-airspace-certificate-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "2ArMyx3l",
    "block": "{\"symbols\":[],\"statements\":[[10,\"a\"],[15,6,[30,[36,1],[[35,0]],null]],[12],[2,\"\\n  \"],[10,\"img\"],[14,\"src\",\"/assets/images/edit-form.svg\"],[14,\"alt\",\"edit\"],[12],[13],[2,\"\\n  \"],[8,\"ember-tooltip\",[],[[\"@text\",\"@side\"],[\"Edit\",\"bottom\"]],null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"url\",\"link-to-react-app\"]}",
    "meta": {
      "moduleName": "admin/templates/components/reusable/airspace-certificates/link-to-edit-airspace-certificate-form.hbs"
    }
  });
});