define("admin/components/ortho-viewer", ["exports", "admin/mixins/google-map-init-mixin"], function (_exports, _googleMapInitMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_googleMapInitMixin.default, {
    showControls: false,
    idSuffix: 'default',
    initMap() {
      var CoordMapType, TILES_LINKS, bounds, latLng, neBound, options, swBound;
      options = {
        zoom: 17,
        tilt: 0,
        mapTypeId: google.maps.MapTypeId['ROADMAP'],
        mapTypeControl: this.get('showControls'),
        streetViewControl: false,
        scaleControl: false,
        panControl: false,
        zoomControl: this.get('showControls'),
        fullscreenControl: this.get('showControls'),
        controlSize: 35,
        zoomControlOptions: {
          style: google.maps.ZoomControlStyle.DEFAULT,
          position: google.maps.ControlPosition.RIGHT_CENTER
        },
        draggable: true,
        disableDoubleClickZoom: false
      };
      if (!this.map) {
        this.map = new google.maps.Map(document.getElementById('ortho' + this.get('ortho.shot.id') + '-' + this.get('idSuffix')), options);
      }
      if (this.get('ortho.mission.location.latitude') && this.get('ortho.mission.location.longitude')) {
        latLng = this.buildLatLng(this.get('ortho.mission.location.latitude'), this.get('ortho.mission.location.longitude'));
      } else {
        latLng = new google.maps.LatLng(37.2350, -115.8111);
      }
      this.map.setCenter(latLng);
      if (this.get('ortho.min_latitude') && this.get('ortho.min_longitude') && this.get('ortho.max_latitude') && this.get('ortho.max_longitude')) {
        swBound = new google.maps.LatLng(this.get('ortho.min_latitude'), this.get('ortho.min_longitude'));
        neBound = new google.maps.LatLng(this.get('ortho.max_latitude'), this.get('ortho.max_longitude'));
        bounds = new google.maps.LatLngBounds(swBound, neBound);
      }
      TILES_LINKS = this.get('ortho.tiles_link') + "{z}/{x}/{y}.png";
      CoordMapType = function (tileSize) {
        this.tileSize = tileSize;
      };
      CoordMapType.prototype.getTile = function (coord, zoom, ownerDocument) {
        var div, doNothing, src;
        doNothing = function (err) {
          var img;
          img = err.target;
          img.src = "";
          img.style.display = 'none';
          return false;
        };
        src = TILES_LINKS.replace('{x}', coord.x).replace('{y}', Math.pow(2, zoom) - coord.y - 1).replace('{z}', zoom);
        div = ownerDocument.createElement('div');
        div.classList.add('ortho-tile');
        div.style.width = this.tileSize.width + 'px';
        div.style.height = this.tileSize.height + 'px';
        div.style.backgroundImage = "url(" + src + ")";
        div.style.zIndex = 1000;
        div.onerror = doNothing;
        div.onError = doNothing;
        return div;
      };
      this.map.overlayMapTypes.insertAt(0, new CoordMapType(new google.maps.Size(256, 256)));
      if (!bounds.isEmpty()) {
        return this.map.fitBounds(bounds);
      }
      return google.maps.event.trigger(this.map, 'resize');
    },
    buildLatLng(lat, lng) {
      lat = Number(lat);
      lng = Number(lng);
      return new google.maps.LatLng({
        lat,
        lng
      });
    }
  });
});