define("admin/templates/components/mission-status-update-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "GQnx/REF",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,2],[[35,0]],null,[[\"default\"],[{\"statements\":[[6,[37,2],[[35,1]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"disabled\"],[14,\"disabled\",\"disabled\"],[14,\"data-toggle\",\"tooltip\"],[14,\"data-placement\",\"top\"],[15,\"title\",[34,1]],[12],[2,\"\\n      \"],[10,\"a\"],[14,0,\"btn btn-secondary btn-sm\"],[14,6,\"#\"],[12],[2,\"\\n        \"],[10,\"i\"],[14,0,\"fa fa-arrow-right\"],[12],[13],[2,\"\\n        \"],[10,\"span\"],[12],[1,[34,0]],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"a\"],[14,0,\"btn btn-secondary btn-sm\"],[14,6,\"#\"],[12],[2,\"\\n      \"],[10,\"i\"],[14,0,\"fa fa-arrow-right\"],[12],[13],[2,\"\\n      \"],[10,\"span\"],[12],[1,[34,0]],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"nextStatus\",\"statusDisabledTooltip\",\"if\"]}",
    "meta": {
      "moduleName": "admin/templates/components/mission-status-update-button.hbs"
    }
  });
});