define("admin/mirage/factories/admin", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberCliMirage.Factory.extend({
    first_name: 'hey',
    last_name: 'you',
    email: 'local_admin@mail.com',
    // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
    roles: ['ops_manager', 'account_rep'],
    BulkMissionUpdateManager: (0, _emberCliMirage.trait)({
      roles: ['ops_manager', 'account_rep', 'bulk_mission_update_manager']
    }),
    financeManager: (0, _emberCliMirage.trait)({
      roles: ['ops_manager', 'bulk_mission_update_manager', 'finance_manager']
    })
  });
});