define("admin/helpers/subtract", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Helper.helper(function sub([value1, value2]) {
    return value1 - value2;
  });
});