define("admin/components/missions/modals/bulk-action/additional-pricing-modal", ["exports", "admin/data/line-item-reasons"], function (_exports, _lineItemReasons) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DEFAULT_ADDITIONAL_PRICING = () => Ember.Object.create({
    reason: null,
    amount: 0,
    description: '',
    hasInteractedReason: false,
    hasInteractedAmount: false,
    hasInteractedDescription: false,
    hasErrorReason: false,
    hasErrorAmount: false,
    hasErrorDescription: false
  });
  var _default = _exports.default = Ember.Component.extend({
    additionalPricing: null,
    lineItemReasons: _lineItemReasons.default,
    formObject: null,
    selectedMissions: null,
    modeOptions: Ember.computed(function () {
      return [{
        value: 'missionPrice',
        label: 'Update mission price directly'
      }, {
        value: 'additionalPricing',
        label: 'Add Additional Pricing'
      }];
    }),
    init() {
      this._super(...arguments);
      this._initializeState();
    },
    _initializeState() {
      this.setProperties({
        lineItemReasons: _lineItemReasons.default,
        additionalPricing: this.get('additionalPricing')?.length ? this.get('additionalPricing') : [DEFAULT_ADDITIONAL_PRICING()],
        formObject: this.get('formObject') || Ember.Object.create({
          pricing: null,
          updatePricing: false,
          updateMode: 'additionalPricing',
          missionPrice: null,
          hasInteractedMissionPrice: false,
          hasErrorMissionPrice: false
        })
      });
    },
    dangerousMissionsCount: Ember.computed('selectedMissions.@each.status', function () {
      if (!this.selectedMissions) return 0;
      const dangerousStatuses = ['invoice_needed', 'invoiced', 'complete'];
      return this.selectedMissions.filter(mission => dangerousStatuses.includes(mission.get('status'))).length;
    }),
    hasDangerousMissions: Ember.computed('dangerousMissionsCount', function () {
      return this.get('dangerousMissionsCount') > 0;
    }),
    displayTotal: Ember.computed('totalPricing', 'formObject.{currencySymbol,currency}', function () {
      let symbol = this.get('formObject.currencySymbol') || '';
      let total = this.get('totalPricing');
      let code = this.get('formObject.currency') || '';
      return `${symbol}${total} ${code}`.trim();
    }),
    totalPricing: Ember.computed('formObject.pricing', 'additionalPricing.@each.{amount,reason}', function () {
      return this._calculateTotalPricing();
    }),
    _calculateTotalPricing() {
      const deductionReasons = ["courtesy", "mission_execution", "reshoot", "refund", "promotion", "scheduling_delay", "uncapturable_assets", "reduced_time_on_site", "price_adjustment_decrease"];
      let basePrice = parseFloat(this.get('formObject.pricing')) || 0;
      let additional = this.additionalPricing.reduce((sum, item) => {
        let amount = parseFloat(item.amount) * 100 || 0;
        return deductionReasons.includes(item.reason) ? sum - amount : sum + amount;
      }, 0);
      return ((basePrice * 100 + additional) / 100).toFixed(2);
    },
    _validatePricingItem(item) {
      const errors = {
        hasErrorReason: !item.reason,
        hasErrorAmount: !item.amount || parseFloat(item.amount) <= 0,
        hasErrorDescription: !item.description || item.description.trim() === ''
      };
      item.setProperties(errors);
      return Object.values(errors).includes(true);
    },
    actions: {
      setUpdateMode(newValue) {
        this.set('formObject.updateMode', newValue);
        if (newValue === 'missionPrice') {
          this.set('additionalPricing', [DEFAULT_ADDITIONAL_PRICING()]);
        } else {
          this.set('formObject.missionPrice', null);
        }
        this.set('formObject.hasErrorMissionPrice', false);
        this.additionalPricing.forEach(item => {
          item.setProperties({
            reason: null,
            amount: 0,
            description: '',
            hasInteractedReason: false,
            hasInteractedAmount: false,
            hasInteractedDescription: false,
            hasErrorReason: false,
            hasErrorAmount: false,
            hasErrorDescription: false
          });
        });
      },
      selectReason(item, event) {
        let selectedValue = event.target.value;
        item.set('hasInteractedReason', true);
        item.set('reason', selectedValue);
        let reason = item.get('reason');
        item.set('hasErrorReason', !reason);
      },
      markMissionPriceAsInteracted() {
        this.set('formObject.hasInteractedMissionPrice', true);
        let price = parseFloat(this.formObject.missionPrice);
        this.set('formObject.hasErrorMissionPrice', isNaN(price) || price < 0);
      },
      markAmountAsInteracted(item) {
        item.set('hasInteractedAmount', true);
        let amount = parseFloat(item.get('amount'));
        item.set('hasErrorAmount', !amount || amount <= 0);
      },
      markDescriptionAsInteracted(item) {
        item.set('hasInteractedDescription', true);
        let description = item.get('description')?.trim();
        item.set('hasErrorDescription', !description);
      },
      toggleUpdatePricing() {
        this.toggleProperty('formObject.updatePricing');
      },
      addAdditionalPricing() {
        this.additionalPricing.unshiftObject(DEFAULT_ADDITIONAL_PRICING());
      },
      removeAdditionalPricing(index) {
        this.additionalPricing.removeAt(index);
      },
      validateAndSubmit() {
        if (this.formObject.updateMode === 'missionPrice') {
          let price = parseFloat(this.formObject.missionPrice);
          if (isNaN(price) || price < 0) {
            this.set('formObject.hasErrorMissionPrice', true);
            return;
          } else {
            this.set('formObject.hasErrorMissionPrice', false);
          }
          this.onSubmitMissionPrice(this.get('formObject'));
          return;
        }
        let hasErrors = false;
        this.additionalPricing.forEach(item => {
          if (this._validatePricingItem(item)) {
            hasErrors = true;
          }
        });
        if (hasErrors) return;
        let validPricing = this.additionalPricing.map(item => item.getProperties('reason', 'amount', 'description'));
        this.onSubmitAdditionalPricing(this.get('formObject'), validPricing);
      },
      closeModal() {
        this.onClose();
      }
    }
  });
});