define("admin/components/missions/pricing", ["exports", "ember-changeset", "ember-changeset-validations", "admin/validations/invoice-line-item", "admin/data/line-item-reasons"], function (_exports, _emberChangeset, _emberChangesetValidations, _invoiceLineItem, _lineItemReasons) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    changeset: null,
    invoiceLineItem: null,
    mission: null,
    showForm: false,
    lineItemReasons: _lineItemReasons.default,
    originCurrency: null,
    didReceiveAttrs() {
      this._super();
      this.set('originCurrency', this.get('mission.currency'));
      this.initNewInvoiceLineItem();
    },
    initNewInvoiceLineItem() {
      this.set('invoiceLineItem', this.store.createRecord('invoice-line-item', {
        saved_invoiceable_type: 'missions',
        saved_invoiceable_id: this.get('mission.id')
      }));
      this.set('changeset', new _emberChangeset.default(this.get('invoiceLineItem'), (0, _emberChangesetValidations.default)(_invoiceLineItem.default), _invoiceLineItem.default));
    },
    wrappedCurrencies: Ember.computed('currencies', function () {
      return this.get('currencies').filterBy('pricing_allowed', true).map(currency => {
        return Ember.Object.create({
          label: `${currency.symbol} ${currency.code}`,
          value: currency,
          disabled: !currency.pricing_allowed
        });
      });
    }),
    currencyChanged: Ember.computed('mission.currency', 'originCurrency', function () {
      return this.get('mission.currency') !== this.get('originCurrency');
    }),
    updateMissionPrice() {
      let price = this.get('mission.invoice_line_items').reduce((acc, item) => acc + item.amount, 0);
      if (price < 0) {
        price = 0;
      }
      this.set('mission.price', price);
    },
    actions: {
      setCurrency(currency) {
        // no persistence until mission is saved
        this.set('mission.currency', currency);
        this.get('mission.invoice_line_items').forEach(invoiceLineItem => invoiceLineItem.set('currency', currency));
      },
      toggleForm() {
        this.toggleProperty('showForm');
      },
      save() {
        this.get('changeset').validate().then(() => {
          if (this.get('changeset.isValid')) {
            this.get('changeset').save().then(() => {
              this.set('invoiceLineItem.newlyAdded', true);
              this.set('showForm', false);
              this.updateMissionPrice();
              this.initNewInvoiceLineItem();
            });
          }
        });
      }
    }
  });
});